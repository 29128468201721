import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import HeaderForm from "../../../components/common/HeaderForm"
import { getBackgroundImage, getGatsbyImage } from "../../../plugins/common"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"
import * as style from "../review.module.scss"

const Header = ({ data, quoteForm, locale, token, location }) => {
  const { title, subtitle, image, backgroundImage } = data
  const bg = getBackgroundImage(backgroundImage)

  return (
    <div
      className={`${style.header} pb-4 position-relative d-lg-grid d-flex flex-column`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={`${style.left} text-lg-start text-center`}>
        <h1 className={`ming`}>
          <CustomMarkdown html={title} color={`copper`} />
        </h1>
        <p className={`ming lora-Bold ${style.desc}`}>{subtitle}</p>
        <GatsbyImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          className={`${style.image}`}
          image={getGatsbyImage(image)}
          alt="alea"
        />
      </div>
      <div className={`${style.right} my-4`}>
        {quoteForm?.enableHeader && (
          <HeaderForm
            data={quoteForm}
            location={location}
            token={token}
            lang={locale}
          />
        )}
      </div>
    </div>
  )
}

export default Header
