import * as style from "./FaqInPage.module.scss"
import React, { useState, useEffect } from "react"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import Icon from "@mdi/react"
import { CustomMarkdown } from "../plugins/sanitizehtml"
import RemoveMarkdown from "remove-markdown"

const FaqInPage = ({
  titleColor,
  title,
  faq,
  addtionalClass,
  meta,
  setMeta,
}) => {
  const [openingIndex, setOpeningIndex] = useState(null)

  const updateQuestions = index =>
    openingIndex === index ? setOpeningIndex(null) : setOpeningIndex(index)

  const getChevon = opened => (opened ? mdiChevronUp : mdiChevronDown)

  useEffect(() => {
    if (faq.length && meta && setMeta) {
      const faqSchema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: faq.map(aq => {
          return {
            "@type": "Question",
            name: aq.title,
            acceptedAnswer: {
              "@type": "Answer",
              text: RemoveMarkdown(aq.content),
            },
          }
        }),
      }
      const tempMeta = { ...meta, organizationSchema: faqSchema }
      setMeta(tempMeta)
    }
  }, [])

  return (
    <div
      className={`${style.faqInPage} ${
        addtionalClass ? style[addtionalClass] : ""
      } `}
    >
      {title && <h2 className={titleColor}>{title}</h2>}
      <div className={`d-flex ${style.context}`}>
        {faq.map((qa, qaIndex) => (
          <div className={`${style.qa} `} key={`questionIndex-${qaIndex}`}>
            <div
              className={`${style.question} row `}
              role="button"
              tabIndex={-1}
              onClick={() => updateQuestions(qaIndex)}
              onKeyDown={() => updateQuestions(qaIndex)}
            >
              <h3 className="col-lg-11 col-10 mb-0 title">{qa.title}</h3>
              <div className="col-lg-1 col-2 question-chevon">
                <Icon path={getChevon(qaIndex === openingIndex)} size={1.5} />
              </div>
            </div>
            <div
              className={`py-5 text-start ${
                qaIndex === openingIndex ? "d-block" : "d-none"
              }`}
            >
              <CustomMarkdown html={qa.content}></CustomMarkdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqInPage
