// extracted by mini-css-extract-plugin
export var alertRed = "review-module--alert-red--cRcb6";
export var anchorMenu = "review-module--anchorMenu--Lh-eC";
export var bgBarGrey = "review-module--bg-bar-grey--65UBD";
export var bgBeige = "review-module--bg-beige--6zgtO";
export var bgCopper = "review-module--bg-copper--qcayS";
export var bgDaffodil = "review-module--bg-daffodil--mLkbL";
export var bgGreyText = "review-module--bg-grey-text--CcYgJ";
export var bgMing = "review-module--bg-ming--iYVor";
export var bgMint = "review-module--bg-mint--DAWlN";
export var bgRed = "review-module--bg-red--wCPLi";
export var bgRedTrans = "review-module--bg-red-trans--kV5jd";
export var bgSand = "review-module--bg-sand--xxxRi";
export var bgSoftSun = "review-module--bg-softSun--mEntA";
export var bgTeal = "review-module--bg-teal--L8K3w";
export var bgWhite = "review-module--bg-white--TvLik";
export var bgWomenSharp = "review-module--bg-women-sharp--+ZeyP";
export var blackText = "review-module--black-text--Uhax0";
export var content = "review-module--content--OT-ll";
export var copper = "review-module--copper--jk8Tv";
export var corners = "review-module--corners--WZ0w4";
export var cursorNormal = "review-module--cursor-normal--jdRhb";
export var cursorPointer = "review-module--cursor-pointer--Yb++1";
export var dUnset = "review-module--d-unset--Xgwj6";
export var darkGrey = "review-module--dark-grey--OJ6YN";
export var dash = "review-module--dash--Cv7ao";
export var desc = "review-module--desc--KMzrS";
export var flex = "review-module--flex--1x9iP";
export var flex1 = "review-module--flex-1--kVJgX";
export var fontSize12 = "review-module--font-size-12--6KOde";
export var fontSize20 = "review-module--font-size-20--ffLFE";
export var form = "review-module--form--I9JzQ";
export var greyPlaceholder = "review-module--grey-placeholder--xd4uR";
export var greyText = "review-module--grey-text--OIvXv";
export var h1sizing = "review-module--h1sizing--fLRWo";
export var h2sizing = "review-module--h2sizing--Q8ebo";
export var header = "review-module--header--px-tF";
export var hidden = "review-module--hidden--23GjB";
export var image = "review-module--image--XtlUl";
export var imageAni = "review-module--imageAni--23+E7";
export var imageZoom = "review-module--imageZoom--rp-UU";
export var imageZoomGatsby = "review-module--imageZoomGatsby--08rOU";
export var italic = "review-module--italic--7yzXz";
export var left = "review-module--left--6g+UE";
export var lightGrey = "review-module--light-grey--JJizj";
export var logo = "review-module--logo--l86bY";
export var lora = "review-module--lora--qBkeC";
export var loraBold = "review-module--lora-Bold--Lc1pW";
export var loraBoldItalic = "review-module--lora-BoldItalic--Mkesc";
export var loraMedium = "review-module--lora-Medium--Hjlqw";
export var loraSemiBold = "review-module--lora-SemiBold--kapmi";
export var main = "review-module--main--VR24m";
export var ming = "review-module--ming--JWtuZ";
export var mingHover2 = "review-module--ming-hover-2--xkAUp";
export var modalOpen = "review-module--modal-open--0yRiS";
export var noUnderline = "review-module--no-underline--TyZLa";
export var openSans = "review-module--openSans--o0CG8";
export var openSansBold = "review-module--openSans-Bold--oe7sL";
export var openSansSemiBold = "review-module--openSans-SemiBold--t4b4j";
export var paragraph = "review-module--paragraph--c7zVs";
export var quotation = "review-module--quotation--3UkJA";
export var reviewBody = "review-module--reviewBody--GOp7J";
export var right = "review-module--right--KmKUI";
export var round = "review-module--round--tsBK8";
export var roundCorners = "review-module--round-corners--+ZKAs";
export var sideBar = "review-module--sideBar--EQzVs";
export var socialIcon = "review-module--socialIcon--jyuPb";
export var softCorners = "review-module--soft-corners--47+mD";
export var softShadows = "review-module--soft-shadows--AOmb8";
export var softerCorners = "review-module--softer-corners--8I4Ty";
export var softyCorners = "review-module--softy-corners--TIMrk";
export var tableShadow = "review-module--table-shadow--OdVTL";
export var teal = "review-module--teal--pmbVu";
export var topNavShadow = "review-module--top-nav-shadow--MdF5a";
export var uppercase = "review-module--uppercase--PsPjn";
export var wFull = "review-module--w-full--X3HP9";
export var white = "review-module--white--OCpIS";
export var womenRed = "review-module--women-red--OS9D4";
export var womenSharp = "review-module--women-sharp--Kvrns";