import React from "react"
import Img from "../../../components/Img"
import * as style from "./planAnchor.module.scss"

const PlanAnchor = ({ partner }) => {
  const { logo, name, slug } = partner
  return (
    <a href={`#${slug}`} className={`no-underline`}>
      <div className={`soft-corners flex p-1 mb-2 ${style.planAnchor}`}>
        <Img image={logo} className={style.partnerLogo} />
        <span className={`openSans-bold ms-2`}>{name}</span>
      </div>
    </a>
  )
}

export default PlanAnchor
