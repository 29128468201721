import React, { useState } from "react"
import Partners from "../../components/brand/Partners"
import FaqInPage from "../../components/FaqInPage"
import Layout from "../../components/Layout"
import SocialIcon from "../../components/SocialIcon"
import {
  formatLongDate,
  getAllLocales,
  getBackgroundImage,
} from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Header from "./components/header"
import PlanAnchor from "./components/planAnchor"
import PlanCard from "./components/planCard"
import SummaryTable from "./components/summaryTable"
import * as style from "./review.module.scss"

const ReviewTemplate = ({ pageContext, location }) => {
  const { form, footerForm, data, labels, general } = pageContext
  const {
    meta,
    localizations,
    locale,
    header,
    quoteForm,
    updated_at,
    dynamicBody,
  } = data
  const { anchorTitle, lastUpdatedLabel } = labels

  const token = general?.aleaToken
  const ogImage = getBackgroundImage(header?.logo)
  const updateTimeStr = formatLongDate(updated_at, locale)
  const quoteForms = {
    ...quoteForm,
    ...footerForm.freeQuote,
  }
  const partnerList = dynamicBody.reduce((list, comp) => {
    return comp.strapi_component === "review.plan"
      ? [...list, { ...comp.partner, price: comp.price, planName: comp.name }]
      : list
  }, [])

  const [metaData, setMetaData] = useState(meta)

  return (
    <Layout
      props={general}
      meta={metaData}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      lang={locale}
      ogImage={ogImage}
      noindex={meta.noindex}
      token={token}
      footerForm={quoteForms.enableFooter ? quoteForms : null}
    >
      <Header
        data={header}
        quoteForm={quoteForms}
        locale={locale}
        token={token}
        location={location}
      />
      <div className={`${style.reviewBody}`}>
        <div className="ms-0">
          <div className="lastUpdate teal lora-Bold">
            {` ${lastUpdatedLabel}: ${updateTimeStr}`}
          </div>
        </div>
        <div className={`${style.content} flex flex-lg-row flex-column`}>
          <div
            className={`col-lg-8 col-12 black-text pe-lg-4 order-1 order-lg-0`}
          >
            {dynamicBody.map((component, index) => (
              <div className={`my-5 `} key={index}>
                {component.strapi_component === "layout.title-richtext" && (
                  <div className={style.paragraph}>
                    <CustomMarkdown
                      html={component.content}
                      toSanitize={false}
                    ></CustomMarkdown>
                  </div>
                )}
                {component.strapi_component === "review.plan" && (
                  <PlanCard data={component} labels={labels} locale={locale} />
                )}
                {component.strapi_component === "layout.faq" && (
                  <FaqInPage
                    title={component.sectionTitle}
                    faq={component.questionAnswer}
                    titleColor="black-text"
                    meta={metaData}
                    setMeta={setMetaData}
                  />
                )}
                {component.strapi_component === "partners.learn-partners" && (
                  <div className={`text-center`}>
                    <Partners data={component} lang={locale} smaller={true} />
                  </div>
                )}
                {component.strapi_component === "review.summary" &&
                  component.display && (
                    <SummaryTable partners={partnerList} labels={labels} />
                  )}
              </div>
            ))}
          </div>
          <div
            className={`col-lg-4 col ${style.sideBar} ming ps-lg-4 col-12 order-0 order-lg-1`}
          >
            <div className={`${style.socialIcon}`}>
              <SocialIcon type="share" />
            </div>
            <div className={`${style.anchorMenu} mt-5`}>
              <div className={"mb-4 ming openSans-Bold"}>{anchorTitle}</div>
              {partnerList.map((p, i) => (
                <PlanAnchor key={i} partner={p} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ReviewTemplate
