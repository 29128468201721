import React from "react"

const PriceRating = ({ price }) => {
  return Array(3)
    .fill(null)
    .map((_, i) => (
      <span key={i} className={`${price >= i + 1 ? "teal" : "grey-text"}`}>
        $
      </span>
    ))
}

export default PriceRating
