import React from "react"
import PriceRating from "./priceRating"
import * as style from "./summaryTable.module.scss"

const SummaryTable = ({ partners, labels }) => {
  const { summaryFirstColLabel, summarySecondColLabel, summaryThirdColLabel } =
    labels
  return (
    <table className={`${style.summaryTable}`}>
      <tr className={`lora-Bold`}>
        <th>{summaryFirstColLabel}</th>
        <th>{summarySecondColLabel}</th>
        <th>{summaryThirdColLabel}</th>
      </tr>
      {partners.map((p, i) => (
        <tr key={i} className={`ming`}>
          <td>
            <a href={`#${p.slug}`}>{p.name}</a>
          </td>
          <td className={`openSans-SemiBold`}>{p.planName}</td>
          <td className={`openSans-Bold`}>
            <PriceRating price={p.price} />
          </td>
        </tr>
      ))}
    </table>
  )
}

export default SummaryTable
