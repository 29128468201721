import "./SocialIcon.scss"

import React from "react"

import CustomIcon from "./CustomIcon"

const SocialIcon = ({ props, type }) => {
  //type
  //1.  social
  //2.  share

  let socialLinks = [
    {
      name: "Facebook",
      url: "https://www.facebook.com/sharer/sharer.php?u=",
      icon: "facebookGreen",
      type: "share",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/sharing/share-offsite/?url=",
      social_media: {
        mdi: "mdiLinkedin",
      },
      icon: "linkedinGreen",
      type: "share",
    },
    {
      name: "Mail",
      url: "mailto:?subject=Check out this information from Alea&body=",
      icon: "mailGreen",
      type: "share",
    },
    {
      name: "Whatsapp",
      url: "https://wa.me/?text=",
      icon: "whatsappGreen",
      type: "share",
    },
    {
      name: "Facebook",
      url: "/",
      social_media: {
        mdi: "mdiFacebook",
      },
      icon: "facebookWhite",
      type: "social",
    },
    {
      name: "LinkedIn",
      url: "/",
      social_media: {
        mdi: "mdiLinkedin",
      },
      icon: "linkedinWhite",
      type: "social",
    },
  ]

  if (props) {
    socialLinks = socialLinks.map(item => {
      if (item.type === type) {
        const data = props.find(social => social.name === item.name)
        item.url = data.hyperlink
      }
      return item
    })
  }

  // const getShareUrl = url => (isBrowser ? url + window?.location.href : "")
  const getShareUrl = url =>
    typeof window !== "undefined" ? url + window?.location.href : ""

  return (
    <div className="socialIcon">
      {socialLinks
        ?.filter(item => item.type === type)
        .map((item, index) => {
          return type === "share" ? (
            <a
              key={index}
              className="icon"
              href={getShareUrl(item.url)}
              target="_blank"
              rel="noreferrer"
            >
              <CustomIcon icon={item.icon} />
            </a>
          ) : (
            <a key={index} className="icon" href={item.url}>
              <CustomIcon icon={item.icon} />
            </a>
          )
          // <Link key={index} className="icon" to={item.url}>
          //   <CustomIcon icon={item.icon} />
          // </Link>
        })}
    </div>
  )
}

export default SocialIcon
