// extracted by mini-css-extract-plugin
export var alertRed = "FaqInPage-module--alert-red--d4Qk5";
export var bgBarGrey = "FaqInPage-module--bg-bar-grey--cxHtS";
export var bgBeige = "FaqInPage-module--bg-beige--tvD4i";
export var bgCopper = "FaqInPage-module--bg-copper--C+EKB";
export var bgDaffodil = "FaqInPage-module--bg-daffodil--MEImJ";
export var bgGreyText = "FaqInPage-module--bg-grey-text--Ql5oi";
export var bgMing = "FaqInPage-module--bg-ming--J-kBC";
export var bgMint = "FaqInPage-module--bg-mint--S6r+h";
export var bgRed = "FaqInPage-module--bg-red--ejDPx";
export var bgRedTrans = "FaqInPage-module--bg-red-trans--sksIC";
export var bgSand = "FaqInPage-module--bg-sand--QniT6";
export var bgSoftSun = "FaqInPage-module--bg-softSun--624YX";
export var bgTeal = "FaqInPage-module--bg-teal--mX3ew";
export var bgWhite = "FaqInPage-module--bg-white--auEnK";
export var bgWomenSharp = "FaqInPage-module--bg-women-sharp--m2ujM";
export var blackText = "FaqInPage-module--black-text--P6E3j";
export var brand = "FaqInPage-module--brand--jaXo5";
export var context = "FaqInPage-module--context--98y81";
export var copper = "FaqInPage-module--copper--Y9Pms";
export var corners = "FaqInPage-module--corners--wVznY";
export var cursorNormal = "FaqInPage-module--cursor-normal--z9swt";
export var cursorPointer = "FaqInPage-module--cursor-pointer--8yIoh";
export var dUnset = "FaqInPage-module--d-unset--tQne1";
export var darkGrey = "FaqInPage-module--dark-grey--Rs066";
export var dash = "FaqInPage-module--dash--RpTBN";
export var faqInPage = "FaqInPage-module--faqInPage--TAEl-";
export var flex = "FaqInPage-module--flex--1gnbI";
export var flex1 = "FaqInPage-module--flex-1--S2O8E";
export var fontSize12 = "FaqInPage-module--font-size-12--kuGz7";
export var fontSize20 = "FaqInPage-module--font-size-20--OfIQg";
export var greyPlaceholder = "FaqInPage-module--grey-placeholder--3szmk";
export var greyText = "FaqInPage-module--grey-text--nXXQK";
export var h1sizing = "FaqInPage-module--h1sizing--jDSAZ";
export var h2sizing = "FaqInPage-module--h2sizing--EeTud";
export var hidden = "FaqInPage-module--hidden--7V1Hw";
export var imageAni = "FaqInPage-module--imageAni--WFI0S";
export var imageZoom = "FaqInPage-module--imageZoom--UHLyU";
export var imageZoomGatsby = "FaqInPage-module--imageZoomGatsby--714Az";
export var italic = "FaqInPage-module--italic--tLosA";
export var lightGrey = "FaqInPage-module--light-grey--rZphK";
export var logo = "FaqInPage-module--logo--sB0Gv";
export var lora = "FaqInPage-module--lora--0UMj4";
export var loraBold = "FaqInPage-module--lora-Bold--+EZDc";
export var loraBoldItalic = "FaqInPage-module--lora-BoldItalic--YiwtZ";
export var loraMedium = "FaqInPage-module--lora-Medium--E8fyC";
export var loraSemiBold = "FaqInPage-module--lora-SemiBold--a5FJN";
export var main = "FaqInPage-module--main--O+HGY";
export var ming = "FaqInPage-module--ming--HoMyV";
export var mingHover2 = "FaqInPage-module--ming-hover-2--Fbkxe";
export var modalOpen = "FaqInPage-module--modal-open---OHMJ";
export var noUnderline = "FaqInPage-module--no-underline--fbVy5";
export var openSans = "FaqInPage-module--openSans--dksuY";
export var openSansBold = "FaqInPage-module--openSans-Bold--h-8JM";
export var openSansSemiBold = "FaqInPage-module--openSans-SemiBold--lWRhf";
export var qa = "FaqInPage-module--qa--KwRAp";
export var question = "FaqInPage-module--question--nRmWQ";
export var quotation = "FaqInPage-module--quotation--xkgh0";
export var round = "FaqInPage-module--round--SQefB";
export var roundCorners = "FaqInPage-module--round-corners--yCnXO";
export var softCorners = "FaqInPage-module--soft-corners--iHsU4";
export var softShadows = "FaqInPage-module--soft-shadows--etlPf";
export var softerCorners = "FaqInPage-module--softer-corners--CAyrR";
export var softyCorners = "FaqInPage-module--softy-corners--Z5MpA";
export var tableShadow = "FaqInPage-module--table-shadow--Yut8a";
export var teal = "FaqInPage-module--teal--F7kWo";
export var topNavShadow = "FaqInPage-module--top-nav-shadow--ItVPV";
export var uppercase = "FaqInPage-module--uppercase--fIzLl";
export var wFull = "FaqInPage-module--w-full--dI7-d";
export var white = "FaqInPage-module--white--P-v71";
export var women = "FaqInPage-module--women--T48rN";
export var womenRed = "FaqInPage-module--women-red--BAjtc";
export var womenSharp = "FaqInPage-module--women-sharp--CmkgF";